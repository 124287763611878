import ModelBase from './shared/model-base.js'

const OPTIONS = {
  i18nModelName: 'obj_vr',
  columns: [
    'id',
    'number',
    'title',
    'scenes',
    'scenes_count',
    'parsed_scenes_count'
  ]
}

export default class ObjVr extends ModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
}
